<template>
  <div class="near">
    <div class="title">
      <span>{{ titleName }}</span>
    </div>

    <swiper-row>
      <swiper-slide 
        v-for="(object, index) in NEAR_TRIPS_OR_PLACES" 
        :key="index"
      >
        <object-card
          type="icon"
          width="488px"
          height="275px"
          :img="object.images[Object.keys(object.images)[0]]"
          :title="object.title"
          :category="object.type.name"
          :icon="object.type.image"
          @click.native="goToObject(object)"
        />
      </swiper-slide>
    </swiper-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ObjectCard from "@/components/Parts/ObjectCard";
import SwiperRow from "@/components/Wrappers/SwiperRow";

export default {
  props: ["title"],
  components: { ObjectCard, SwiperRow },
  computed: {
    ...mapGetters(["NEAR_TRIPS_OR_PLACES"]),
    titleName() {
      return this.$route.query.title;
    }
  },
  methods: {
    ...mapActions([
      "GET_TRIP_OR_PLACE",
    ]),

    goToObject(object) {
      this.GET_TRIP_OR_PLACE(object);

      if (this.$route.path === `/object/${object.id}`) return;

      this.$router.push({ path: `/object/${object.id}`,
        query: {
          object: object,
          title: "Другие экскурсии и маршруты"
        }
      });

      const scrollBox = document.querySelector(".right-side .content");
      scrollBox ? scrollBox.scrollTo(0, 0) : null;
      const wrapper = document.querySelector(".content-wrapper");
      wrapper ? wrapper.scrollTo(0, 0) : null;
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.near {
  position: relative;
  width: 100%;
  margin-top: 90px;
  margin-bottom: 45px;

  &::before {
    position: absolute;
    top: 0;
    content: "";
    width: calc(100% - 64px);
    height: 4px;
    background: $color_grey_3;
    margin: 0 32px;
  }

  .title {
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.75px;
    color: rgba(#fff, 0.97);
    margin-left: 32px;
    padding-top: 44px;
    margin-bottom: 44px;
  }

  .swiper-slide {
    width: 488px;
    margin-right: 8px;
    margin-left: 32px;
    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>