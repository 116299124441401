<template>
  <div class="object-full" v-if="TRIP_OR_PLACE">
    <swiper class="photos" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="(img, index) in TRIP_OR_PLACE.images" :key="index">
        <img :src="img" alt />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="left-side">
        <div class="title">{{TRIP_OR_PLACE.title}}</div>
        <div class="contacts">
          <div v-if="TRIP_OR_PLACE.contacts !== null" class="contact contact-phone">
            <div class="contact-phone__img">
              <phone />
            </div>
            <div class="contact-phone__text">
              {{ TRIP_OR_PLACE.contacts.phone }}
            </div>
          </div>
          <div v-if="TRIP_OR_PLACE.contacts  !== null" class="contact contact-website">
            <div class="contact-phone__img">
              <website />
            </div>
            <div class="contacts-website__text">
              {{ TRIP_OR_PLACE.contacts.website }}
            </div>
          </div>
          <div v-if="TRIP_OR_PLACE.contacts !== null" class="contact contact-email">
            <div class="contacts-email__text">
              {{ TRIP_OR_PLACE.contacts.email }}
            </div>
          </div>
        </div>
        <object-item-adds class="adds" :adds="TRIP_OR_PLACE.additions" />
        <div class="qrcode" v-if="TRIP_OR_PLACE.qr_code">
          <div>
            <div class="qr">
              <img :src="TRIP_OR_PLACE.qr_code" alt />
            </div>
            <div class="description">Забронировать номер c помощью QR-кода</div>
          </div>
        </div>
      </div>

      <scrolled-content class="right-side">
        <tag :title="TRIP_OR_PLACE.type.name || tagName" />
        <div class="text" v-html="TRIP_OR_PLACE.description"></div>
      </scrolled-content>
    </div>

    <div
      v-if="TRIP_OR_PLACE.direction"
      class="imap-fullpopup-map"
    >
      <div class="imap-fullpopup-map__cont">
        <yandex-map
          :coords="[coordinates[0], coordinates[1]]"
          zoom="16"
          :map-type="'map'"
          ymap-class="yandex-map"
          :controls="['zoomControl']"
        >
          <ymap-marker
            v-for="(item, index) in TRIP_OR_PLACE.places"
            :class="{'active-marker': activeSlide === item.id}"
            :key="index"
            :marker-id="item.id"
            :coords="item.location"
            :icon="{
              imageSize: [40, 40],
              layout: 'default#imageWithContent',
              imageHref: '',
              content: `${index + 1}`,
              contentLayout: activeSlide === item.id ? markerActiveIcon : markerIcon
            }"
          />

          <ymap-marker
            :coords="TRIP_OR_PLACE.direction"
            :marker-id="TRIP_OR_PLACE.id"
            marker-type="Polyline"
            :marker-fill="{
              color: '#f25423'
            }"
            :marker-stroke="{
              width: 8
            }"
          />

        </yandex-map>
      </div>
    </div>

    <div class="wrapper-swiper">
      <swiper
        class="swiper-places"
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2,
          freeMode: true
        }"
      >
        <swiper-slide
          v-for="(item, index) in TRIP_OR_PLACE.places"
          :key="index"
          @click.native="clickPlace(item)"
          :class="{'slider-active': activeSlide === item.id}"
        >
          <div class="content-card">
            <div class="content-card__img">
              <img :src="item.images[0]">
              <span class="numeration">{{ index + 1 }}</span>
            </div>
            <div class="content-card__info">
              <div class="content-card__title">
                {{ item.title }}
              </div>
              <div class="content-card__address">
                {{ item.address }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ObjectItemAdds from "@/components/Parts/ObjectItemAdds";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";
import Phone from "@/components/Icons/Phone";
import Website from "@/components/Icons/Web";

export default {
  components: { ObjectItemAdds, ScrolledContent, Tag, Phone, Website },
  data() {
    return {
      map: null,
      activeSlide: null,
      coordinates: [],
      place: null,
      markerActiveIcon: `<div style='display: flex; align-items: center; justify-content: center; width: 80px; height: 80px; font-size: 40px; color: #fff; border-radius: 100%; border: 2px solid #000; background: #f76268;'>$[properties.iconContent]</div>`,
      markerIcon: `<div style='display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; font-size: 28px; color: #fff; border-radius: 100%; border: 2px solid #000; background: #f76268;'>$[properties.iconContent]</div>`
    };
  },

  mounted() {
    // первый обьект в маршруте устанавливаем как центр карты
    console.log(this.TRIP_OR_PLACE);
    if (this.TRIP_OR_PLACE.places.length > 0) {
      this.coordinates = [this.TRIP_OR_PLACE.places[0].location[0], this.TRIP_OR_PLACE.places[0].location[1]];
    } else {
      this.coordinates = [this.GET_STOP.lat, this.GET_STOP.lon]
    }
  },

  computed: {
    ...mapGetters([
      "TRIP_OR_PLACE",
      "GET_STOP",
    ]),

    tagName() {
      return this.$route.query.object?.subtype.name || "";
    },
  },
  methods: {
    mapInitHandler(map) {
      this.map = map;
    },

    clickPlace(place) {
      console.log(place, 'place');
      this.place = place;
      this.activeSlide = place.id;
      this.coordinates = place.location
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";
.contacts {
  margin-bottom: 20px;
}
.contact {
  display: flex;
  align-items: center;
}

.wrapper-swiper {
  position: absolute;
  bottom: -45px;
  width: 100%;
}

.slider-active {
  border: 2px solid #f25423;
}

.marker-active {
  width: 80px;
  height: 80px;
  font-size: 40px;
  line-height: 32px;
}

.swiper-slide {
  width: 500px;
  height: 224px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 14px;
  background-color: #2a2a2c;

  .content-card {
    display: flex;

    &__img {
      position: realtive;
      width: 185px;
      height: 185px;
      margin: 20px;

        img {
          width: inherit;
          height: inherit;
          border-radius: 12px;
        }

        .numeration {
          position: absolute;
          top: 32px;
          left: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          font-size: 28px;
          line-height: 32px;
          color: #fff;
          background-color: #f87c56;
          border-radius: 100%;
          border: 2px solid #fff;
        }

    }

    &__info {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      color: #fff;
    }

    &__title {
      height: 100%;
      line-height: 30px;
      font-size: 24px;
      font-weight: 500;
    }

    &__address {
      font-size: 24px;
      font-weight: normal;
      line-height: 30px;
    }
  }
}

.imap-fullpopup-map {
  background: linear-gradient(#20152d, #20152d), linear-gradient(#e9eafe, #d8d1ff);
  background-blend-mode: exclusion, hard-light;
  &__cont {
    .ymap-container {
      height: 799px;

    }
  }
}

.object-full {
  position: relative;

  .photos {
    margin-top: 56px;

    .swiper-slide {
      width: 884px;
      height: 442px;

      margin-left: 32px;
      margin-right: 12px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  .content {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;

    .left-side {
      width: 488px;
      margin-left: 32px;
      margin-right: 40px;
      text-align: left;

      .title {
        width: 488px;
        font-size: 44px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: rgba(#fff, 0.97);
        padding-bottom: 40px;
        border-bottom: 4px solid rgba($color_grey_3, 0.6);
        margin-bottom: 32px;
      }

      .qrcode {
        width: 100%;
        border-top: 4px solid rgba($color_grey_3, 0.6);
        margin-top: 32px;
        padding-top: 32px;
        & > div {
          overflow: hidden;
          width: 364px;
          height: 128px;
          border-radius: 12px;
          background-image: $grad_yellow_grey2;
          display: flex;
          .qr {
            width: 128px;
            height: 128px;
            background: #fff;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .description {
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            font-size: 20px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: -0.2px;
            color: #fff;
            width: 187px;
            margin: 22px 24px;
          }
        }
      }
    }
    .right-side {
      width: 508px;
      // width: auto;
      max-height: 646px;

      .text {
        margin-top: 32px;
        width: 488px;

        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: rgba(#fff, 0.8);
        position: relative;
        padding-bottom: 32px;
      }
    }
  }
}
</style>
